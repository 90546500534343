<template>
  <div v-if="type === 'all'">
    <LoadingScreen v-if="isSyncing" :type="isSyncing ? 'syncing' : 'default'" />
    <div v-else class="new-empty-state">
      <div class="welcome-text">
        <p data-cy="empty-state-welcome-text">Let's bring your designs to life! How would you like to start?</p>
      </div>
      <div class="welcome-options">
        <div class="welcome-option">
          <div class="option-content">
            <div class="option-icons">
              <img :src="require('@/assets/svg/code-icons/figma.svg')" width="24" height="24" />
              <div class="icon-divider"></div>
              <img :src="require('@/assets/svg/code-icons/react.svg')" width="24" height="24" />
              <img :src="require('@/assets/svg/code-icons/html.svg')" width="24" height="24" />
              <img :src="require('@/assets/svg/code-icons/vue.svg')" width="24" height="24" />
              <img :src="require('@/assets/svg/code-icons/tailwind.svg')" width="24" height="24" />
            </div>
            <h3 class="option-title">✨ New! Start from the AI Playground</h3>
            <p class="option-text">
              Got a Figma URL? This is for you! Paste it into the Playground and chat with AI to iterate and refine your
              code.
            </p>
          </div>
          <an-button
            class="playground-option-cta"
            data-cy="empty-state-playground"
            @click="onOpenAIPlayground"
            variant="primary"
            >Open AI Playground</an-button
          >
        </div>
        <div class="welcome-option">
          <div class="option-content">
            <div class="option-icons">
              <img :src="require('@/assets/svg/code-icons/vscode.svg')" width="24" height="24" />
              <img :src="require('@/assets/svg/code-icons/figma.svg')" width="24" height="24" />
              <img :src="require('@/assets/svg/code-icons/xd.svg')" width="24" height="24" />
            </div>
            <div>
              <h3 class="option-title">Start from your design tool or VSCode</h3>
              <p class="option-text">
                Working in Figma, XD, or VSCode? Anima’s plugin lets you inspect, sync, and turn full flows into code.
              </p>
            </div>
          </div>
          <p data-cy="empty-state-plugin" @click="newProject" class="option-cta">Open new project & Get plugin</p>
        </div>
      </div>
    </div>
    <div class="new-empty-state-mobile">
      <img :src="require('@/assets/svg/desktop-computer.svg')" width="245" height="120" />
      <p class="mobile-title">Switch to desktop</p>
      <p class="mobile-text mobile-text-upper">Welcome to Anima!</p>
      <p class="mobile-text">Let's create your first project. You'll need a desktop for this part.</p>
    </div>
  </div>
  <div v-else-if="type === 'archive'" class="empty-state-container">
    <div class="title">No archived projects yet</div>
    <div>Here you can store team projects that are no longer active</div>
  </div>
  <div v-else-if="type === 'shared'" class="empty-state-container">
    <div class="title">Hey look, nothing!</div>
    <div>Seems like no one shared projects with you yet...</div>
  </div>
</template>

<script>
import LoadingScreen from '@/components/Loading/LoadingScreen';
import { mapActions, mapGetters, mapState } from 'vuex';
import { openModal } from '@/services/bus';

export default {
  data() {
    return {
      isSyncing: false,
      itemsType: 'project',
      columns: [
        {
          title: 'Designers',
          content: 'Sync your design from Sketch, Figma, or XD using our plugin to start your first project.',
          linkLabel: 'Install plugin',
          onClick: this.navigateToPlugins
        },
        {
          title: 'Developers',
          content:
            'Start your first project by inviting your team designer to sync the design & get code components in HTML, React or Vue.',
          linkLabel: 'Invite to team',
          onClick: this.inviteToTeam
        }
      ]
    };
  },
  props: {
    type: {
      type: String,
      default: 'all',
      validator: (value) => ['all', 'archive', 'shared'].includes(value)
    },
    layout: {
      type: String,
      default: 'grid'
    }
  },
  components: {
    LoadingScreen
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters({ isUserDeveloper: 'users/isUserDeveloper', isActiveExperiment: 'experiments/isActive' }),
    ...mapGetters('webappSystem', ['onboardingDetails']),
    gridStyle() {
      const { itemsType, isMobile } = this;
      let min, columnGap, rowGap;

      if (isMobile) {
        min = itemsType === 'project' ? '335px' : '120px';
        columnGap = '20px';
        rowGap = '30px';
      } else {
        min = itemsType === 'project' ? '300px' : '220px';
        columnGap = '30px';
        rowGap = '40px';
      }
      return {
        display: 'grid',
        justifyContent: 'start',
        columnGap,
        rowGap,
        gridTemplateColumns: `repeat(auto-fill, minmax(${min}, 1fr))`
      };
    }
  },
  methods: {
    ...mapActions({ pollSyncingProject: 'projects/pollSyncingProject' }),
    onNewProjectClick() {
      this.$trackEvent('team-empty-state.new-project-button.click');
      this.$emit('click-new-project');
    },
    onOpenAIPlayground() {
      this.$trackEvent('team-empty-state.playground.click');
      window.open('https://dev.animaapp.com', '_blank');
    },
    onOpenVSCodeExtension() {
      this.$trackEvent('team-empty-state.vscode.click');
      window.open('https://marketplace.visualstudio.com/items?itemName=AnimaApp.vscode-anima', '_blank');
    },
    inviteToTeam() {
      this.$trackEvent('team-empty-state.invite-to-team.click');
      this.$router.push({ name: 'team-members' });
    },
    navigateToPlugins() {
      this.$trackEvent('team-empty-state.navigate-to-plugins.click');
      this.$router.push({ name: 'plugins' });
    },
    newProject() {
      const { isUserDeveloper } = this;
      this.$trackEvent('team-empty-state.new-project.click', { isUserDeveloper });
      openModal({ name: 'new-project' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';

.new-empty-state {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.new-empty-state-mobile {
  display: none;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    margin: 20px auto 0 auto;
  }

  .mobile-title {
    color: var(--primary-colour-dark-grey, #3b3b3b);
    text-align: center;
    font-family: Roslindale;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -1px;
    margin: 40px 0;
  }
}

.playground-option-cta.primary {
  background: var(--playground-purple) !important;

  &:hover:not(:disabled) {
    background: var(--playground-purple-hover) !important;
  }
}

.mobile-text {
  color: var(--primary-colour-dark-grey, #3b3b3b);
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.mobile-text-upper {
  margin-bottom: 30px;
}

.welcome-options {
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.welcome-option {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  flex: 0 1 340px;
  height: 355px;
  margin-right: 30px;
  border-radius: 10px;
  border: 1px solid var(--deviders-grey, #d8d8d8);
  background: var(--white, #fff);
  padding: 40px 24px;

  @media screen and (max-width: 1280px) {
    padding: 25px 15px;
    height: 320px;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    margin: 20px 0;
    flex: 0 0 300px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.welcome-text {
  text-align: center;
  margin-bottom: 40px;
  color: var(--primary-colour-dark-grey, #3b3b3b);
  text-align: center;
  font-family: Mulish;
  font-size: 17px;
  font-weight: 600;
}

.option-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  gap: 10px;
}

.icon-divider {
  border-radius: 10px;
  border-right: 1px solid var(--deviders-grey, #d8d8d8);
  background: var(--white, #fff);
}

.option-title {
  text-align: center;
  color: var(--primary-colour-dark-grey, #3b3b3b);
  font-family: Roslindale Deck;
  font-size: 24px;
  font-weight: 500;
  line-height: 2rem;
  max-width: 250px;

  @media screen and (max-width: 1280px) {
    font-size: 20px;
    line-height: 1.5rem;
  }
}

.option-text {
  margin: 16px auto 0 auto;
  max-width: 220px;
  text-align: center;
  color: var(--primary-colour-dark-grey, #3b3b3b);
  font-family: Mulish;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.option-cta {
  display: inline-block;
  text-align: center;
  color: var(--primary-colour-dark-grey, #3b3b3b);
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &:hover {
    font-weight: 600;
    color: var(--primary-colour-dark-grey, #3b3b3b);
    cursor: pointer;
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.title {
  @include headline;
}
</style>
