<template>
  <section class="container dark">
    <TopBar @close="onClose" closeButton />
    <div class="content">
      <div class="illustration">
        <img src="@/assets/illustrations/quirky-clock-dark.svg" width="200" height="120" />
      </div>
      <h1 class="title">Logging out...</h1>
      <p class="body">You will be redirected to the login page shortly.</p>
    </div>
  </section>
</template>

<script>
import TopBar from '@/components/Onboarding/TopBar';
import auth from '@/auth';

export default {
  name: 'logout',
  components: {
    TopBar
  },
  mounted() {
    this.applyTheme('dark', 'onboarding');
    this.handleLogout();
  },
  methods: {
    async handleLogout() {
      try {
        await this.deleteCurrentDevice();
      } finally {
        this.$trackEvent('logout-page.logout');
        auth.logout();
        this.$router.replace({ name: 'login', query: this.$route.query });
        window.location.reload();
      }
    },
    onClose() {
      this.$trackEvent('logout-page.close-button.click');
      auth.logout();
      this.$router.push({ name: 'login', query: this.$route.query }, () => {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.illustration {
  margin-bottom: 2rem;
  img {
    aspect-ratio: 3/2;
    height: unset;
  }
}

.title {
  margin-bottom: 1rem;
}

.body {
  color: var(--secondary-text);
}
</style>
