import Vue from 'vue';
import store from '@/store';
// eslint-disable-next-line no-unused-vars
import VueRouter, { RouteConfig } from 'vue-router';
import auth from '@/auth';
import { beforeEnterModal, beforeSignupOrLogin } from '@/router/navigationGuards';

import Root from '@/views/Root.vue';
import Team from '@/views/Team.vue';
import Project from '@/views/Project.vue';
import Login from '@/views/Login.vue';
import VerifyEmail from '@/views/VerifyEmail.vue';
import FigmaAPICallback from '@/views/FigmaAPICallback.vue';
import FigmaAPIGenerateToken from '@/views/FigmaAPIGenerateToken.vue';
import Paired from '@/views/Paired.vue';
import GithubPaired from '@/views/GithubPaired.vue';
import FigmaIntent from '@/views/FigmaIntent.vue';
import Signup from '@/views/Signup.vue';
import Onboarding from '@/views/Onboarding/Onboarding';
import OnboardingFirstTeam from '@/views/Onboarding/FirstTeam';
import OnboardingFirstTeamPricing from '@/views/Onboarding/FirstTeamPricing';
import Shared from '@/views/Shared.vue';
import OmniView from '@/views/OmniView.vue';
import SyncPreview from '@/views/SyncPreview';
import SyncWebsite from '@/views/SyncWebsite';
import PageNotFound from '@/views/PageNotFound';
import ChromeExtensionUninstall from '@/views/ChromeExtensionUninstall';
import ForgotPassword from '@/views/ForgotPassword';
import LoginWithSSO from '@/views/LoginWithSSO.vue';
import ResetPassword from '@/views/ResetPassword';
import Learn from '@/views/Learn.vue';
import DevicesLimitReached from '@/views/DevicesLimitReached';
import Logout from '@/views/Logout.vue';

import ProjectsList from '@/components/Team/ProjectsList';
import TeamSettings from '@/components/Team/Settings/Settings';
import errorHandler from '@/services/errorHandler';

import api from '@/api';
import { EventBus } from '@/services/bus';
import { createCookie } from '@/utils/cookie';

Vue.use(VueRouter);

const getTeamSettingsPaths = (name) => {
  const children = ['general', 'members', 'permissions', 'billing', 'delete'].map((section) => {
    const childName = `${name}-${section}`;
    return {
      name: childName,
      path: section,
      beforeEnter: beforeEnterModal({ name: childName, onCloseRedirect: { name } })
    };
  });
  return {
    name,
    path: 'settings',
    component: TeamSettings,
    children
  };
};

const getTeamPaths = () => {
  const name = 'team';
  const path = '/team/:teamSlug';
  const settingsName = `${name}-settings`;
  return {
    path,
    component: Team,
    children: [
      {
        name,
        path: '',
        component: ProjectsList,
        props: { type: 'all' }
      },
      {
        name: `${name}-archive`,
        path: 'archive',
        component: ProjectsList,
        props: { type: 'archive' }
      },
      {
        name: `${name}-members`,
        path: 'members',
        beforeEnter: beforeEnterModal({
          name: 'team-settings-members',
          calcRedirect: (to, from) => {
            if (from.name === 'new-team') {
              return { name: 'team', params: to.params };
            }
            return null;
          }
        }),
        meta: {
          noLimitsOnDevices: true
        }
      },
      {
        name: `${name}-pricing`,
        path: 'pricing',
        beforeEnter: beforeEnterModal({
          name: 'pricing',
          calcRedirect: (_, from) => /onboarding|payment/.test(from.name) && { name: 'team' }
        })
      },
      {
        name: `${name}-payment`,
        path: 'payment',
        beforeEnter: (to, from, next) => {
          const { params } = to;
          // HOTFIX BLACK FRIDAY BUG
          if (params?.teamSlug == 'lisa-s-onboarding-team-gcqamtx') {
            next({ name: 'payment', query: to.query });
          } else {
            beforeEnterModal({
              name: 'payment',
              calcRedirect: (_, from) => /onboarding|payment/.test(from.name) && { name: 'team' }
            })(to, from, next);
          }
        },
        children: [
          {
            name: `${name}-payment-success`,
            path: 'success',
            beforeEnter: beforeEnterModal({
              name: 'payment-success',
              calcRedirect: (_, from) => /onboarding|payment/.test(from.name) && { name: 'team' }
            })
          }
        ]
      },
      {
        name: `${name}-payment-confirmation`,
        path: 'payment-confirmation',
        beforeEnter: (to, from, next) => {
          const { params } = to;
          // HOTFIX BLACK FRIDAY BUG
          if (params?.teamSlug == 'lisa-s-onboarding-team-gcqamtx') {
            next({ name: 'payment', query: to.query });
          } else {
            beforeEnterModal({
              name: 'payment-confirmation',
              calcRedirect: (_, from) => /onboarding|payment/.test(from.name) && { name: 'team' }
            })(to, from, next);
          }
        }
      },
      {
        name: `${name}-downgrade`,
        path: 'downgrade',
        beforeEnter: beforeEnterModal({ name: 'downgrade', onCloseRedirect: { name: 'team-settings-billing' } })
      },
      {
        name: `${name}-cancel-plan`,
        path: 'downgrade/cancel',
        beforeEnter: beforeEnterModal({ name: 'cancel-plan', onCloseRedirect: { name: 'team-settings-billing' } })
      },
      {
        name: 'new-project',
        path: 'project/new',
        beforeEnter: beforeEnterModal({ name: 'new-project' })
      },
      {
        name: `${name}-viewer-access`,
        path: 'access',
        beforeEnter: beforeEnterModal({ name: 'viewer-access' })
      },
      getTeamSettingsPaths(settingsName)
    ]
  };
};

/**
 * @type {RouteConfig[]}
 */
const routes = [
  {
    name: 'logout',
    path: '/logout',
    component: Logout,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'root',
    component: Root,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: 'shared',
        path: 'shared',
        component: Shared
      },
      {
        name: 'plugins',
        path: 'plugins',
        beforeEnter: beforeEnterModal({ name: 'plugins', mode: 'dark' })
      },
      {
        name: 'learn',
        path: 'learn',
        component: Learn
      },
      {
        name: 'pricing',
        path: 'pricing',
        beforeEnter: async (to, from, next) => {
          const teamSlug = await store.dispatch('users/getDefaultTeamSlug');
          const params = { teamSlug };
          next({ name: 'team-pricing', params });
        }
      },
      {
        name: 'payment',
        path: 'payment',
        beforeEnter: async (to, from, next) => {
          // lisa-s-onboarding-team-gcqamtx
          const { query = {} } = to;
          const teamSlug = await store.dispatch('users/getDefaultTeamSlug');
          const params = { teamSlug };

          if (!query.plan || !query.interval) {
            return next({ name: 'team-pricing', params });
          }

          next({ name: 'team-payment', params, query });
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'payment-confirmation',
        path: 'payment-confirmation',
        beforeEnter: async (to, from, next) => {
          // lisa-s-onboarding-team-gcqamtx
          const { query = {} } = to;
          const teamSlug = await store.dispatch('users/getDefaultTeamSlug');
          const params = { teamSlug };

          if (!query.plan || !query.interval) {
            return next({ name: 'team-pricing', params });
          }

          next({ name: 'team-payment-confirmation', params, query });
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'connect-slack',
        path: '/slack',
        beforeEnter: beforeEnterModal({
          name: 'connect-slack'
        }),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'default-team-redirect',
        path: 'my-team/*',
        beforeEnter: async (to, from, next) => {
          try {
            const { params, query } = to;
            const teamSlug = await store.dispatch('users/getDefaultTeamSlug');

            next({ path: `/team/${teamSlug}/${params.pathMatch}`, query });
          } catch (err) {
            next({ name: 'page-not-found' });
          }
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'chrome-extension-uninstall',
        path: '/chrome-extension-uninstall',
        component: ChromeExtensionUninstall
      },
      {
        name: 'account',
        path: 'account',
        beforeEnter: beforeEnterModal({ name: 'account' })
      },
      {
        name: 'new-team',
        path: 'team(s)?/new',
        beforeEnter: beforeEnterModal({ name: 'new-team' })
      },
      {
        name: 'switch-to-desktop',
        path: 'switch',
        beforeEnter: beforeEnterModal({
          name: 'switch-to-desktop',
          fsHideCloseButton: true,
          onCloseRedirect: { name: 'root' }
        })
      },
      getTeamPaths(),
      {
        name: 'temp-team-invitation-backward-compatibility',
        path: 'join/:teamSlug/:invitationCode',
        redirect: (to) => {
          const {
            params: { teamSlug, invitationCode }
          } = to;
          const params = { teamSlug };
          const query = { invite: invitationCode };
          return { name: 'team', params, query };
        }
      },
      {
        name: 'project',
        path: '/team/:teamSlug/project/:projectId',
        component: Project,
        children: [
          {
            name: 'project-components',
            path: 'components',
            props: { type: 'component' },
            beforeEnter(to, _, next) {
              const user = store.state.users.currentItem;
              if (!user?.is_admin) {
                next({ name: 'project', params: to.params });
              } else next();
            }
          },
          {
            name: 'design-import',
            path: 'import',
            beforeEnter: beforeEnterModal({ name: 'design-import', onCloseRedirect: { name: 'project' } })
          },
          {
            name: 'whats-next',
            path: 'whats-next',
            beforeEnter: beforeEnterModal({ name: 'whats-next', onCloseRedirect: { name: 'project' } })
          },
          {
            name: 'project-settings',
            path: 'settings',
            beforeEnter: beforeEnterModal({ name: 'project-settings', onCloseRedirect: { name: 'project' } }),
            children: [
              {
                name: 'project-settings-general',
                path: 'general',
                alias: '',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  props: { defaultTabLabel: 'General' }
                })
              },
              {
                name: 'project-settings-guests',
                path: 'guests',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  props: { defaultTabLabel: 'Members' }
                })
              },
              {
                name: 'project-settings-public-link',
                path: 'public-link',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  onCloseRedirect: { name: 'project' },
                  props: { defaultTabLabel: 'Publish' }
                })
              },
              {
                name: 'project-settings-tracking-seo',
                path: 'tracking-seo',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  onCloseRedirect: { name: 'project' },
                  props: { defaultTabLabel: 'Tracking & SEO' }
                })
              },
              {
                name: 'project-settings-files',
                path: 'files',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  props: { defaultTabLabel: 'Files' }
                })
              }
            ]
          },
          {
            name: 'project-invite',
            path: 'invite',
            beforeEnter: beforeEnterModal({
              name: 'project-invite',
              onCloseRedirect: { name: 'project' },
              props: { eventSource: 'face-pile' }
            })
          },
          {
            name: 'project-share',
            path: 'invite',
            beforeEnter: beforeEnterModal({
              name: 'project-invite',
              onCloseRedirect: { name: 'project' },
              props: { eventSource: 'share-button' }
            })
          },
          {
            name: 'archive-project',
            path: 'archive',
            beforeEnter: beforeEnterModal({ name: 'archive-project' })
          },
          {
            name: 'delete-project',
            path: 'delete',
            beforeEnter: beforeEnterModal({ name: 'delete-project' })
          }
        ]
      },
      {
        name: 'temp-project-backward-compatibility',
        path: '/p/:projectId',
        component: Project
      }
    ]
  },
  {
    name: 'omniview',
    path: '/team/:teamSlug/project/:projectId/screen/:screenSlug/omniview',
    component: OmniView,
    meta: {
      requiresAuth: true
    }
  },
  // Backward support for preview page
  {
    path: '/p/:projectShortId/:releaseShortId/:screenSlug/prototype',
    name: 'syncPreview',
    component: SyncPreview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/p/:projectShortId/:releaseShortId/:screenSlug/syncwebsite',
    name: 'syncWebsite',
    component: SyncWebsite,
    meta: {
      requiresAuth: true
    }
  },
  // omniview for a release for chrome extension
  {
    path: '/p/:projectShortId/:releaseShortId/:screenSlug/omniview',
    name: 'releaseOmniview',
    component: SyncPreview,
    meta: {
      requiresAuth: true
    }
  },
  // Backward support for single comment
  {
    path: '/p/:projectId/:screenSlug/comments/:commentNumber',
    component: OmniView,
    meta: {
      mode: 'comments',
      requiresAuth: true
    }
  },
  {
    name: 'signup',
    path: '/signup',
    component: Signup,
    beforeEnter: beforeSignupOrLogin
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter: beforeSignupOrLogin
  },
  {
    name: 'devices-limit-reached',
    path: '/devices-limit-reached',
    component: DevicesLimitReached,
    meta: {
      requiresAuth: true,
      noLimitsOnDevices: true
    }
  },
  {
    name: 'gitver',
    path: '/gitver',
    beforeEnter() {
      window.location.href = `https://www.github.com/AnimaApp/anima-web/commit/${process.env.VUE_APP_GIT_VERSION}`;
      return;
    }
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    name: 'sso-login',
    path: '/sso-login',
    component: LoginWithSSO
  },
  {
    name: 'reset-password',
    path: '/reset-password/:token',
    component: ResetPassword
  },
  {
    name: 'github-paired',
    path: '/github-paired',
    component: GithubPaired
  },
  {
    name: 'paired',
    path: '/paired',
    component: Paired,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'figma-intent',
    path: '/figma-intent',
    component: FigmaIntent,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'onboarding',
    path: '/onboarding',
    component: Onboarding,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'onboarding-team',
    path: '/onboarding/team',
    component: OnboardingFirstTeam,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'onboarding-pricing',
    path: '/onboarding/:teamSlug/pricing',
    component: OnboardingFirstTeamPricing,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'figma-generate-token',
    path: '/figma/generate-token',
    component: FigmaAPIGenerateToken
  },
  {
    name: 'figma-callback',
    path: '/figma/callback',
    component: FigmaAPICallback
  },
  {
    name: 'verify-email',
    path: '/verify/:token',
    component: VerifyEmail,
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'temp-team-backward-compatibility',
    path: '/:teamSlug',
    redirect: { name: 'team' },
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'project-settings-backward-compatibility',
    path: '/project/settings/:projectId/:section',
    beforeEnter: async (to, from, next) => {
      const { projectId, section = '' } = to.params;
      const { team_slug: teamSlug } = await store.dispatch('projects/fetchOne', { id: projectId });
      const params = { projectId, teamSlug };
      let routeName = 'project-settings';

      if (['files', 'general'].includes(section.toLowerCase())) {
        routeName = `project-settings-${section}`;
      }

      next({ name: routeName, params });
    },
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'project-prototype-backward-compatibility',
    path: '/p/s/:projectId/:subdomain/:screenSlug/prototype',
    beforeEnter: async (to, _, next) => {
      const { projectId, screenSlug } = to.params;
      const { team_slug: teamSlug } = await store.dispatch('projects/fetchOne', { id: projectId });
      const params = { projectId, teamSlug, screenSlug };

      next({ name: 'omniview', params });
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'omniview-short-link',
    path: '/p/:projectId/s/:screenSlug',
    beforeEnter: async (to, _, next) => {
      const { projectId, screenSlug } = to.params;
      const { team_slug: teamSlug } = await store.dispatch('projects/fetchOne', { id: projectId });

      const params = { projectId, teamSlug, screenSlug };

      next({
        path: `/team/${teamSlug}/project/${projectId}/screen/${screenSlug}/omniview/`,
        params,
        query: to.query,
        replace: true
      });
    },
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'personal-plan-backward-compatibility',
    path: '/settings/personal/plan(/change)?',
    redirect: (to) => ({ name: 'pricing', query: to.query }),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'team-pricing-backward-compatibility',
    path: '/settings/:teamSlug/plan/change',
    redirect: (to) => ({ name: 'team-pricing', params: to.params }),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'personal-account-backward-compatibility',
    path: '/account/settings/personal/account',
    redirect: { name: 'account' },
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'latest-project-release',
    path: '/latest-project-release/:email',
    beforeEnter: async (to, _, next) => {
      try {
        const { email: user_email } = to.params;
        const params = { user_email };
        const {
          data: { url }
        } = await api.get('/get_last_project_release', null, { params });

        if (!url) throw 'NO URL! :(';

        next(url);
      } catch (error) {
        errorHandler.captureException(error);

        next({ name: 'root' });
      }
    },
    meta: {
      requiresAuth: true
    }
  },

  // keep this last k? thx.
  {
    name: 'page-not-found',
    path: '*',
    component: PageNotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const routeRequiresAuth = ({ matched: matchedRoutes }) => {
  return matchedRoutes.some((route) => route.meta.requiresAuth);
};

router.beforeEach(async (to, from, next) => {
  // plugins have hard-coded pairing URL with hash mode
  // beware, HOTFIX before you:
  if (to.fullPath.startsWith('/#')) {
    const path = to.fullPath.slice(2);
    return next(path);
  }

  // track webapp page-view events.
  if (to.name && to.name !== from.name) {
    store.dispatch('tracking/trackPageView', to);
  }

  // route doesn't requires authentication? redirect away.
  if (!routeRequiresAuth(to)) {
    return next();
  }

  // oh boy! route DOES require authentication.
  // get token from url if needed.
  if (to.query.t) {
    localStorage.setItem('token', to.query.t);
  }

  // oh boy! look at that impersonator there... 😏
  if (to.query.method === 'impersonate') {
    localStorage.setItem('animp', true);
    createCookie('impersonating', '1');
  }

  const toPayment = to.name?.includes('payment');
  const toRoot = to.name === 'root' || to.fullPath === '/';

  // retreive access token from pairing token if possible.
  if (!auth.isLoggedIn() && to.query.pt) {
    try {
      const { data } = await api.get('/rpc/pairing_token', null, { params: { t: to.query.pt, otu: true } });
      if (data && data.access_token) {
        localStorage.setItem('token', data.access_token);
      }
    } catch (error) {
      console.log(error);
      errorHandler.captureExceptionAndTrack(error, { name: 'silent-pairing.failure' });
    }
  }

  // not logged in, sorry, redirect to login/signup page.
  if (!auth.isLoggedIn()) {
    const routeName = toPayment ? 'signup' : 'login';
    const routeQuery = toRoot ? {} : { redirect: to.fullPath };

    return next({ name: routeName, query: routeQuery });
  }

  // user can be logged in! yay! get their details!
  try {
    // fetch user.
    const user = await store.dispatch('users/fetchOne', { id: 'me' });

    // check user devices limit if needed.
    if (
      !to.meta.noLimitsOnDevices &&
      !(user.email.includes('ghostinspectoruser') || user.email.includes('@animaapp.com'))
    ) {
      const isDeviceLimitReached = await store.dispatch('devices/isDeviceLimitReached');
      if (isDeviceLimitReached) {
        return next({ name: 'devices-limit-reached' });
      }
    }

    // root = default team page.
    if (toRoot) {
      const teamSlug = await store.dispatch('users/getDefaultTeamSlug');
      return next({ name: 'team', params: { teamSlug } });
    }

    // looks like some HOTFIX? ask @amir;
    if (to.path !== from.path) {
      EventBus.$emit('set-experiments');
    }
  } catch (error) {
    console.log(error);
    const status = error?.response?.status;

    // unauthorized error.
    if (status === 401) {
      errorHandler.captureExceptionAndTrack(error, { name: 'webapp.auth.failure' });
      return next({ name: 'login', query: to.query });
    }

    // some other error.
    errorHandler.captureExceptionAndTrack(error, { name: 'webapp.network.failure' });
    EventBus.$emit('network-error');
  }

  // ALWAYS KEEP IN THE END OF THE FUNCTION!
  // if we reached here - it's safe to continue.
  next();
});

export default router;
