<template>
  <div class="team-header">
    <div class="details" v-if="!isEmpty(team)">
      <an-tooltip :text="uploadTooltipText" v-if="isDesktop">
        <UploadTeamLogo :team="team" @success="onUploadSuccess" @click.native="onUploadClick" />
      </an-tooltip>
      <div v-else style="width: 36px">
        <UploadTeamLogo :team="team" @success="onUploadSuccess" @click.native="onUploadClick" :size="36" />
      </div>
      <div class="name-and-members">
        <div class="team-name-editor">
          <NameChangeGhostInput
            v-if="teamName"
            v-model="teamName"
            :key="team.name"
            @blur="updateName"
            :disabled="loading"
          />
        </div>
        <an-tooltip class="members-container" text="Invite members">
          <Members :members="teamMembers" showMore @click="onMembersClick" />
        </an-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es';
import { mapState, mapActions, mapGetters } from 'vuex';
import Members from '@/components/Members/Members';
import NameChangeGhostInput from '@/components/Input/NameChangeGhostInput';
import UploadTeamLogo from '@/components/Team/UploadTeamLogo';
import { toastError } from '@/services/bus';
import { SlackMixin } from '@/mixins';

export default {
  data() {
    return {
      teamName: '',
      loading: false,
      url: '/v2/uploads/teams/team_logo',
      isWorkingSlack: false
    };
  },
  components: {
    Members,
    NameChangeGhostInput,
    UploadTeamLogo
  },
  mounted() {
    this.teamName = this.team.name;
  },
  mixins: [SlackMixin],
  computed: {
    ...mapState('teamMemberships', { teamMembers: 'team' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters('notificationsSettings', ['slackSettings']),
    uploadTooltipText() {
      const { logo_url } = this.team;
      return logo_url ? 'Update team icon' : 'Add your team icon';
    },
    uploadUrl() {
      return `/v2/uploads/teams/${this.team.id}/logo_url`;
    },
    logoSize() {
      return this.isMobile ? 36 : 50;
    }
  },
  methods: {
    ...mapActions({
      fetchTeam: 'teams/fetchOne',
      updateTeam: 'teams/update',
      fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      createNotificationsSettings: 'notificationsSettings/create',
      setCurrentTeamProps: 'teams/setCurrentTeamProps'
    }),
    isEmpty,
    onNewProjectClick() {
      this.$trackEvent('team-page.new-project-button.click');
      this.$emit('click-new-project');
    },
    onUploadClick() {
      this.$trackEvent('team-page.upload-team-icon.click');
    },
    onUploadSuccess() {
      const { id } = this.team;
      this.$trackEvent('team-page.upload-team-icon.success');
      this.fetchTeam({ id, skipCache: true });
    },
    onMembersClick() {
      this.$trackEvent('team-page.team-members.click');
      const params = { ...this.$route.params, eventSource: 'face-pile' };
      this.$router.push({ name: 'team-members', params });
    },
    async updateName() {
      const { id, name: oldName } = this.team;
      const teamName = this.teamName && this.teamName.trim();
      const payload = { name: teamName };

      if (!teamName || teamName === oldName) {
        this.teamName = oldName;
        return;
      }

      this.$trackEvent('team-page.inline-change-team-name.submit');

      try {
        this.loading = true;
        this.setCurrentTeamProps({ name: teamName });
        await this.updateTeam({ id, payload });
        this.fetchTeam({ id, skipCache: true });
        this.fetchUserMemberships({ id: 'me', skipCache: true });
      } catch (err) {
        toastError("We couldn't change your team's name");
        this.setCurrentTeamProps({ name: teamName });
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    team() {
      this.teamName = this.team.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.team-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  width: 100%;
  .details {
    max-width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .name-and-members {
      margin-left: 7px;
      @include desktop {
        display: flex;
        align-items: center;
      }
    }
    .members-container {
      @include desktop {
        margin-left: 60px;
      }
    }
    .team-name-editor {
      max-width: 500px;
    }
  }
  .new-project-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 20px;
    & > * + * {
      margin-left: 20px;
    }
  }
}
.inline {
  display: flex;
  align-items: center;
  .slack-icon {
    margin-right: 10px;
  }
}
</style>
