<template>
  <div class="container">
    <div class="title">Anima Plugin</div>
    <div class="subtitle">Download Anima plugin and start syncing your design</div>
    <div class="body">
      <div class="plugins-container">
        <div v-for="plugin in plugins" :key="plugin.name" class="plugin-box">
          <div class="plugin-icon">
            <img :src="require('@/assets/svg/code-icons/' + plugin.icon + '.svg')" width="90" height="90" />
          </div>
          <div class="plugin-title">Anima for {{ plugin.name }}</div>
          <div class="plugin-description">
            {{ plugin.description }}
          </div>
          <div class="download-button">
            <an-button @click="navigateToDownload(plugin)" variant="primary">Download plugin</an-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'plugins',
  data() {
    return {
      plugins: [
        {
          icon: 'figma',
          name: 'Figma',
          downloadUrl: 'https://www.figma.com/community/plugin/857346721138427857/Anima',
          description:
            'Inspect code directly in Figma, or sync full flows to Anima to share interactive prototypes and convert designs to code.'
        },
        {
          icon: 'xd',
          name: 'XD',
          downloadUrl: 'https://adobe.com/go/xd_plugins_discover_plugin?pluginId=542412cd',
          description: 'Sync your XD designs to Anima, share outstanding prototypes and convert your design to code.'
        },
        {
          icon: 'vscode',
          name: 'VSCode',
          downloadUrl: 'https://marketplace.visualstudio.com/items?itemName=AnimaApp.vscode-anima',
          description:
            'Import your Figma link into VSCode, and get code by leveraging your own components and code conventions.'
        }
      ]
    };
  },
  computed: mapState('users', { user: 'currentItem' }),
  methods: {
    navigateToDownload({ name, downloadUrl }) {
      this.$trackEvent('plugins.download.click', { plugin: name });
      this.$gtm.trackEvent({
        event: `download_${name.toLowerCase()}_plugin`,
        event_category: 'Download',
        event_action: `${name} Plugin`,
        event_label: this.user?.role
      });
      window.open(downloadUrl, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_mixins.scss';
.body {
  width: auto;
}
.plugins-container {
  display: flex;
}
.plugin-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 24px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  width: 340px;
  height: 422px;
  + .plugin-box {
    margin-left: 40px;
  }
  .plugin-icon {
    margin-bottom: 24px;
  }
  .plugin-title {
    @include secondary-title;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .plugin-description {
    text-align: center;
    margin-bottom: 24px;
    flex: 1;
  }
  .download-button {
    width: 100%;
    text-align: center;
    .an-button {
      width: auto;
    }
  }
}
</style>
